<template>
  <div class="row" >
    <div class="titlecol"
      :style="{ width: calcWidth}" 
      v-for="col in info"
      :key="col.id">
      <p v-html="col"></p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'TableTitle',
  props:{
    info: Array
  },
  computed:{
    calcWidth:function(){
      return 100/this.info.length+'%' ;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row{
  display:flex;
  border-bottom: 1px solid #787572;
  padding-left: 12px;
  color:#787572;
  font-weight:600;
  font-size: 14px;
}
.titlecol{
  display:flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
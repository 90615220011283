<template>
  <div>
    <h1>Стоимость препаратов</h1>
    
    <CheckBox :title=" 'С учетом региональной оптовой надбавки ' + opt + '%'" :checked="costFlag" v-on:click.native="flagCheck()" />
    <CostTable  :refFl="flRef" :costFlag="costFlag" :changeFlag="changeFlag"/> 
    <div class="btns">
      <router-link
        to="/drug-info"
        class="next_btn">
        Открыть информацию о препаратах
      </router-link>
      <router-link
        to="/therapy"
        class="next_btn">
        Продолжить
      </router-link>
      <div class="change_btn" v-on:click="savePrice">
        {{changeFlag ? 'Сохранить ' : 'Изменить '}}цены
      </div>
      <div class="change_btn" v-on:click="priceReset">
        Вернуть цены по умолчанию
      </div>
    </div>
    <p class="substr">{{comment}}</p>
  </div>
</template>

<script>
import CheckBox from '@/components/CheckBox.vue';
import drugSet from '@/views/drugStore';
import initPrice from '@/views/initPrice';
import dataStore from '@/views/data';
import CostTable from '@/components/table/CostTable.vue'

export default {
  components:{
    CheckBox,
    CostTable
  },
  data:function(){
    return{
      costFlag:false,
      changeFlag:false,
      flRef: false,
      drugImage:{
        // {name:'Ниволумаб', dose:'10 мл', color:'#03417A', cost:85460, pic:"nivo100.png"},
        // {name:'Ниволумаб', dose:'4 мл', color:'#0084BD', cost:34183, pic:"nivo100.png"},
        // {name:'Ипилимумаб', dose:'&nbsp;', color:'#00C013', cost:204747, pic:"ipili.png"}
        1:{pic:"nivo100.png", color:'#03417A'},
        2:{pic:"nivo100.png", color:'#0084BD'},
        3:{pic:"ipili.png", color:'#00C013'}
      },
      drugs:[
        {name:'Ниволумаб', dose:'10 мл', color:'#03417A', cost:85460, pic:"nivo100.png"},
        {name:'Ниволумаб', dose:'4 мл', color:'#0084BD', cost:34183, pic:"nivo100.png"},
        {name:'Ипилимумаб', dose:'&nbsp;', color:'#00C013', cost:204747, pic:"ipili.png"}]
    }
  },
  computed:{
    opt() {
        return dataStore.currMult;
      },
    comment:function(){
      let str;

      if (!this.costFlag)
        return '* По умолчанию указана зарегистрированная предельная отпускная цена';
      else {
        if(dataStore.currLawDoc.length==0) return '* Регион не выбран, расчет выполнен для надбавки 10%';
        else return '* '+dataStore.currLawDoc; 
      }
    }
  },
  methods:{

    savePrice: function() {

      this.changeFlag=!this.changeFlag;      
//!!Todo сопоставить текущие цены с исходными!
      if(this.changeFlag==false) {
//        this.costFlag = false;      
      }
      this.flRef = !this.flRef;
    },

    priceReset: function() {

      let currDrug;
      const keys = Object.keys(drugSet);
        keys.forEach((key, index) => {
          currDrug = JSON.parse(JSON.stringify(initPrice[key]));
          drugSet[key] = currDrug;
        })
      this.changeFlag = false;
      this.flRef = !this.flRef;    

    },
    costdisplay:function(x){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    flagCheck:function(){
      this.costFlag=!this.costFlag;
      dataStore.flInterest = this.costFlag;
    }
  },
  mounted() {
    this.costFlag = dataStore.flInterest;
  }
}
</script>
<style scoped>
h1{
  margin: 10px 0 20px 0;
}
.dtable{
  display: flex;
  justify-content: space-around;
  margin: 40px -18px 0 -18px;
}
.dtable p{
  text-align: center;
}
.substr{
  text-align: center;
  font-size: 13px;
  color: #747474;
  margin-top: 30px;
  /*padding-bottom:30px;*/
}
.next_btn, .change_btn{
  margin: 15px 0px 0px 0px ;
  display: block;  
  background-color: #03417A;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  text-align: center;
  padding: 12px 34px;
  cursor: pointer;
}
.change_btn{
  background-color:#0084bd;
}
.btns{
  display: flex;
  justify-content: space-between; 
}

</style>
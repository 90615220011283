<template>
    <div class="checkbox none-selectable" v-bind:class="{ active: checked }">
        <p><span v-bind:class="{ active: checked }"><font-awesome-icon icon="square" /></span></p><p>{{title}}</p>
    </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props:{
      title: String,
      checked: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.checkbox{
    cursor:pointer;
    display: flex;
    align-items:center;  
    margin-bottom: 10px;
    padding: 0px;
    color:#787572;
    font-size: 17px;
    line-height: 17px;
    text-decoration:none;
}
.checkbox span{    
    align-self: center;
    border: 4px solid #787572;
    color: transparent;
    border-radius: 2px;
    display: inline-block;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    margin-right: 4px;
    font-size: 11px;
    text-align: center;
}
.checkbox p{
    margin:0;
}
.active{
    border-color:#03417A !important;
    color: #03417A !important;
}
</style>
<template>
  <div>
    <h1>Информация о препаратах</h1>
    <div class="tablebox">
      <div class="row" >
        <div class="col"><span class="drugname" :style="{color:'#03417A'}">Ниволумаб</span></div>
        <div class="col"><span class="drugname" :style="{color:'#00C013'}">Ипилимумаб</span></div>
      </div>
      <div class="row" >
        <div class="col">Детальную информацию о показаниях к применению и режиме дозирования препарата можно посмотреть в инструкции по медицинскому применению препарата ЛП-№(000287)-(РГ-RU) на сайте Государственного Реестра Лекарственных Средств <a href="http://grls.rosminzdrav.ru/grls.aspx">http://grls.rosminzdrav.ru/grls.aspx</a></div>
        <div class="col">Детальную информацию о показаниях к применению и режиме дозирования препарата можно посмотреть в инструкции по медицинскому применению препарата ЛП-№(000196)-(РГ-RU) на сайте Государственного Реестра Лекарственных Средств <a href="http://grls.rosminzdrav.ru/grls.aspx">http://grls.rosminzdrav.ru/grls.aspx</a></div>
      </div>
    </div>    
    <router-link
        to="/info"
        class="next_btn">
        Закрыть информацию о препаратах
    </router-link>
    <p class="substr">Сообщить о неблагоприятных побочных эффектах при применении препарата ниволумаб вы можете по телефону 8 800 2501212 (звонки бесплатные по всей России) или по электронной почте medinfo.russia@bms.com"</p>
  </div>
</template>

<script>
import drugSet from '@/views/drugStore';
import initPrice from '@/views/initPrice';
import dataStore from '@/views/data';

export default {
  components:{
  },
  computed:{
  },
  methods:{
  }
}
</script>
<style scoped>
h1{
  margin: 10px 0 20px 0;
}
.substr{
  text-align: center;
  font-size: 13px;
  color: #747474;
  margin-top: 30px;
  padding-bottom:30px;
}
.tablebox {
  background-color:rgb(255 255 255 / 40%);
  padding: 10px 20px;
}
.row{
  display:flex;
  border-bottom: 1px solid #787572;
  padding: 12px 0;
}
.drugname{
  text-align:center;
  width:100%;
  display:block;
  font-weight: 600;
  color:#787572;
  margin: 5px 0px;
}
.col{
  width:50%;
  margin: 0 10px;
  align-items: center;
  justify-content: center;
  color:#787572;
}
.next_btn{
  width:300px;
  margin: 15px auto;
  display: block;  
  background-color: #03417A;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  text-align: center;
  padding: 11px 34px;
  cursor: pointer;
}
</style>
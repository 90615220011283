<template>
  <div class="tablebox">
    <TableTitle :info='tdata[0]'/>
    <div
      class="row" 
      v-for="(druginfo,index) in tdata"
      v-if="index>0"
      :index="index"
      :key="druginfo.id"
    >
      <div class="drugcol">
        <p>
          <span class="drugname" :style="{color:druginfo[0].color}">{{druginfo[0].name}}</span><br>
          <span class="drugdose" v-html="druginfo[0].dose"></span>
        </p>
      </div>
      <div class="col">
        <p>{{ druginfo[1] }}</p>
      </div>
      <div class="col">
        <input type="text"
        :id="'drugedit'+druginfo[3]" 
        :value="druginfo[2]"
        v-on="{
          keypress: e=>updPrice(e),
          focusout: e=>updPrice(e)
        }" 
        :readonly="!changeFlag"
        :class="{'fixcost' : !changeFlag, 'editcost' : changeFlag}"
      />
      </div>
    </div>

  </div>
</template>

<script>
import drugSet from '@/views/drugStore';
import initSet from '@/views/initPrice';
import dataStore from '@/views/data';
import TableTitle from '@/components/table/TableTitle.vue'

export default {
  name: 'Table',
  components:{
    TableTitle
  },
  props:{
    width: Number,
    costFlag: Boolean,
    changeFlag:Boolean,
    refFl: Boolean
  },

  data:function(){
    return{
      flRef:false
    }
  },

  methods:{

    completeEdit: function(e) {

    let val;
    let num = e.target.id;
    let price = document.getElementById(num).value;

    price = price.replace(/ /g, '');
    val = parseFloat(price.replace(/,/g, '.'));
    val = Math.round(val*100)/100;
    num = num.slice(8);
    drugSet[num].price = val;
    this.flRef = !this.flRef;
    // State.drugStore.saveData(num);

  },

    updPrice: function(e){

      if(e.type=='keypress') {
        if(e.charCode==13) {
          this.completeEdit(e);
          return;
        }
        if((e.charCode<48 || e.charCode>57) && e.charCode!=46 
          && e.charCode!=44) { 
          e.preventDefault();
          return;
        }
        // Intercept second delimeter
        let num = e.target.id;
        let txt = document.getElementById(num).value;
        let ind = txt.indexOf('.');
        let ind2 = txt.indexOf(',');
        if(ind>=0 || ind2>=0){
          if(e.charCode==46 || e.charCode==44) {
            e.preventDefault();
            return;
          }
        }
      }
      if(e.type=='focusout') {
          this.completeEdit(e);
      } 
    }
  },
  computed:{
    title:function(){
      if (!this.costFlag)
        return 'Минимальная тендерная цена';
      else
        return 'Цена упаковки с оптовой надбавкой'
    },
    tdata:function(){

      let currDrug = {}, line = [], price, vatPrice, drugData = {}, dose, color, ref, ref1, initPrice;
      let tableData = [['', this.title + '<br>без НДС<sup>*</sup>, руб', this.title + '<br>c НДС<sup>*</sup>, руб']];
      ref = this.flRef;
      ref1 = this.refFl;
      const keys = Object.keys(drugSet);
        keys.forEach((key, index) => {
          currDrug = drugSet[key];
          line = [];
          drugData = {};
          drugData.name = currDrug.name;
          dose = '' + currDrug.dose;
          dose = dose + ' мг';
          drugData.dose = dose;
          color = '#787572';
          if(key==1) color = '#03417A';
          if(key==2) color = '#00C013';
          if(key==3) color = '#0084BD';
          drugData.color = color;
          line.push(drugData);
          initPrice = initSet[key].price;
          if(this.costFlag) {
            vatPrice = currDrug.price;
            if(vatPrice!=initPrice) {
              vatPrice = currDrug.price;
              price = currDrug.price*100.0/(100.0+dataStore.vat);
            }
            else {
              price = currDrug.price*100.0/(100.0+dataStore.vat);
              price = Math.ceil(price + price*dataStore.currMult/100.0);
              vatPrice = Math.ceil(price + price*dataStore.vat/100.0);
            }
          } else {
            price = currDrug.price*100.0/(100.0+dataStore.vat);
            vatPrice = currDrug.price;
          }
          line.push(dataStore.padDigits(Math.round(price)));
          line.push(dataStore.padDigits(Math.round(vatPrice)));
          line.push(key);
//!!Todo Костыль для фильтрации препаратов
// На самом деле нужно по dataStore.currIndic выбрать действительные схемы из State.pathMethods
// Затем проитерировав схемы выбрать все входящие в эти схемы коды препаратов.
// Затем при формировании сета препратов смотреть, входит ли он в отобранный набор
          if((dataStore.currIndic>=4 && key>=4) || key<4)// Показываем препараты химио только для РЛ и вне зависимости от показания
            tableData.push(line);
        })
      return tableData;    
       // return [
       //   ['', this.title + '<br>без НДС<sup>*</sup>, руб', this.title + '<br>c НДС<sup>*</sup>, руб'],
       //   [this.drugs[0], '200000', '2000000'],
       //   [this.drugs[1], '100000', '1000000'],
       //   [this.drugs[2], '500000', '5000000']
       // ]
    },
    drugsData:function(){
        let data=[], drug = {}, card = {}, price;
        for(let i=1 ; i<4 ; i++) {
          card = {};
          drug = drugSet[i];
          card.name = drug.name;
          card.dose = drug.dose + ' мг';
          card.color = this.drugImage[i].color;
          if(this.costFlag) {
            price = drug.price*100.0/(100.0+dataStore.vat);
            price = price + price*dataStore.currMult/100.0;
            card.cost = Math.ceil(price + price*dataStore.vat/100.0);
          } else {
            card.cost = drug.price;
          }
          card.pic = this.drugImage[i].pic;
          data.push(card);
        }
        return data;
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tablebox {
  background-color:rgb(255 255 255 / 40%);
  padding: 10px 20px;
}
.table-remark {
  color: #787572;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  margin-left: 14px;
}

.row{
  display:flex;
  border-bottom: 1px solid #787572;
  padding-left: 12px;
}
.col{
  width:33%;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#787572;
}
.drugcol{
  width:33%;
  display:flex;
  align-items: center;
  }
.drugcol p{
  margin: 7px 0;
}
.drugname{
  font-weight: 600;
  color:#787572;
  margin: 5px 0px;
}
.drugdose{
  margin: 5px 0px;
  color:#787572;
}
input{
  font-size:16px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  padding: 7px 0;
  display: block;
  margin: auto;
}
.fixcost{
  border: 0;
  background: none;
  color: #787572;
}
.editcost{
  background: #c5dee6;
  border: 1px solid #fff;
  color: #03417a;
}

</style>